/* eslint-disable max-lines */
export function bbot_visuals_cat_reduce(data: any, chartConfig: any) {

    if (!data) {
        return;
    }

    // order the values
    data = data.sort((a: any, b: any) => {
        return b[chartConfig.dataValue] - a[chartConfig.dataValue];
    });

    if (data.length > chartConfig.dataOthersLimit) {

        const total = data.reduce((a: any, b: any) => a + b[chartConfig.dataValue], 0);
        const total_n = data.length;

        data = data.slice(0, chartConfig.dataOthersLimit);

        const othersObject = JSON.parse(JSON.stringify(data[data.length - 1]));
        othersObject[chartConfig.dataValue] = total - (data.reduce((a: any, b: any) => a + b[chartConfig.dataValue], 0));
        othersObject[chartConfig.dataLabel] = "Others (" + (total_n - chartConfig.dataOthersLimit) + ")";
        data.push(othersObject);
    }

    return data;
}

export function bbot_visuals_onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
}

export function bbot_visuals_splitDataByGroup(dataset: any, group_column: any, label_column: any, group_config: any, bar_config: any, auto_colour: any) {

    const groups: any[] = [];
    const grouped_datasets: { [id: string]: any[] } = {};
    const grouped_labels = [];
    for (const g of dataset) {

        if (!groups.includes(g[group_column])) {
            groups.push(g[group_column]);
            grouped_datasets[g[group_column]] = [];
        }
        grouped_labels.push(g[label_column]);
        grouped_datasets[g[group_column]].push(g);

    }

    const return_ret = [];

    let auto_colour_dict: { [id: string]: any } = {};
    if (auto_colour) {
        auto_colour_dict = bbot_visuals_categorical_colour();
    }

    for (const [kk, vv] of Object.entries(grouped_datasets)) {
        return_ret.push(
            Object.assign(
                {
                    label: kk,
                    data: vv,
                    backgroundColor: auto_colour ? auto_colour_dict[kk] : group_config[kk].backgroundColor
                },
                bar_config)
        );
    }

    return [grouped_labels.filter(bbot_visuals_onlyUnique), return_ret];

}

export function bbot_visuals_splitDataByGroupSimple(dataset: any, group_column: any) {

    const groups: any[] = [];
    const grouped_datasets: { [id: string]: any[] } = {};
    const grouped_datasets_sub_label: { [id: string]: any[] } = {};
    const grouped_datasets_sub_value: { [id: string]: any[] } = {};
    const grouped_labels = [];
    for (const g of dataset) {

        if (!groups.includes(g[group_column])) {
            groups.push(g[group_column]);
            grouped_datasets[g[group_column]] = [];
            grouped_datasets_sub_label[g[group_column]] = [];
            grouped_datasets_sub_value[g[group_column]] = [];
        }
        grouped_labels.push(g[group_column]);
        grouped_datasets[g[group_column]].push(g);

    }

    return grouped_datasets;
}

export function bbot_visuals_categorical_colour() {

    return [];
}

export function override_column_names(cols: any, new_: any) {

    const new_dict = new_.reduce((r: any, o: any) => {
        Object.keys(o).forEach((k) => { r[k] = o[k]; });
        return r;
    }, {});

    const new_cols = [];
    for (const cc of cols) {
        new_cols.push({ title: new_dict[cc.title] ?? cc.title });
    }
    return new_cols;
}

export function remove_reorder_columns(data: any, cols_dict: any, column_names: any) {

    const orderedDictVal: { [id: string]: string | number } = {};
    for (const [jj, col_tt] of cols_dict.entries()) {
        orderedDictVal[col_tt.title] = jj;
    }

    const new_data = [];
    const new_col_dict = [];

    let row_idx = 0;
    for (const row of data) {
        const tmp_row = [];
        for (const column of column_names) {
            const new_col_name = Object.values(column)[0] as string;
            const col_idx = orderedDictVal[new_col_name];
            if (row_idx === 0) {
                new_col_dict.push({ title: new_col_name });
            }
            tmp_row.push(row[col_idx]);
        }
        new_data.push(tmp_row);
        row_idx = row_idx + 1;
    }

    return [new_data, new_col_dict];
}

export function flattenDictValue(input_array: any, key_of_i = "label") {

    const ret = [];
    for (const tmp_dict of input_array) {
        const tmp_new: { [id: string]: string | number } = {};
        tmp_new[Object.keys(tmp_dict)[0]] = tmp_dict[Object.keys(tmp_dict)[0]][key_of_i];
        ret.push(tmp_new);
    }
    return ret;
}

export function transform_grid_data_and_filter(datag: any, config: any) {
    const column_names = flattenDictValue(config.columnDef);
    let cols_dict = [];
    for (const [key,] of Object.entries(datag)) {
        cols_dict.push({ title: key });
    }

    const rows = Object.keys(datag[cols_dict[0].title]);

    const data = [];
    for (const r of rows) {
        const tmp_data = [];
        for (const [key,] of Object.entries(datag)) {
            tmp_data.push(datag[key][r]);
        }
        data.push(tmp_data);
    }

    cols_dict = override_column_names(cols_dict, column_names);

    const new_data = remove_reorder_columns(data, cols_dict, column_names);

    const data_ret = new_data[0];
    cols_dict = new_data[1];

    // data_ret = update_timestamp_column(data_ret, cols_dict, ["Date Seen"]);
    return data_ret;
}

export function transform_grid_data(datag: any) {
    const cols_dict = [];
    for (const [key,] of Object.entries(datag)) {
        cols_dict.push({ title: key });
    }
    const rows = Object.keys(datag[cols_dict[0].title]);
    const data = [];
    let row_id = 0;
    for (const r of rows) {
        const tmp_data: { [id: string]: string | number } = { id: row_id++ };
        for (const [key,] of Object.entries(datag)) {
            tmp_data[key] = (datag[key][r]);
        }
        data.push(tmp_data);
    }
    return data;
}

export function create_colour_dict(value_array: string[]) {
    // make sure the array is distinct and finc out how many we have
    const distinct_values: string[] = [...new Set(value_array)];
    const ret_dict: { [id: string | number]: string } = {};
    let i = 0;
    const selected_palette = COLORB.BeebotSet["10"];
    const palette_length = selected_palette.length;
    for (const dv of distinct_values) {
        const idx = i % palette_length;
        ret_dict[dv] = selected_palette[idx];
        i = i + 1;
    }
    return ret_dict;

}

export const COLORB = {
    schemeGroups: {
        sequential: ["BuGn", "BuPu", "GnBu", "OrRd", "PuBu", "PuBuGn", "PuRd", "RdPu", "YlGn", "YlGnBu", "YlOrBr", "YlOrRd"],
        singlehue: ["Blues", "Greens", "Greys", "Oranges", "Purples", "Reds"],
        diverging: ["BrBG", "PiYG", "PRGn", "PuOr", "RdBu", "RdGy", "RdYlBu", "RdYlGn", "Spectral"],
        qualitative: ["Accent", "Dark2", "Paired", "Pastel1", "Pastel2", "Set1", "Set2", "Set3"]
    }, YlGn: {
        3: ["#f7fcb9", "#addd8e", "#31a354"],
        4: ["#ffffcc", "#c2e699", "#78c679", "#238443"],
        5: ["#ffffcc", "#c2e699", "#78c679", "#31a354", "#006837"],
        6: ["#ffffcc", "#d9f0a3", "#addd8e", "#78c679", "#31a354", "#006837"],
        7: ["#ffffcc", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#005a32"],
        8: ["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#005a32"],
        9: ["#ffffe5", "#f7fcb9", "#d9f0a3", "#addd8e", "#78c679", "#41ab5d", "#238443", "#006837", "#004529"]
    }, BeebotSet: {
        10: ["#00C2A2", "#2E6F60", "#275A5A", "#79B8D9", "#568FAD", "#3F718B", "#2E596F", "#234252"]
    }
};

export function get_new_data(rows: any, datag: any, new_col_dict: any) {
    const new_data = [];
    for (const r of rows) {
        const tmp_data: { [id: string | number]: string } = {};
        for (const [key,] of Object.entries(datag)) {
            tmp_data[(new_col_dict[key])] = datag[key][r];
        }
        new_data.push(tmp_data);

    }
    return new_data;
};

export function MapDisplayName(coreDataIn: any, mappingData: any, fieldsToMap: string[]) {
    if (!coreDataIn || !mappingData) {
        return coreDataIn;
    }
    const coreData = JSON.parse(JSON.stringify(coreDataIn));
    // eslint-disable-next-line guard-for-in
    for (const x of Object.keys(coreData)) {
        // eslint-disable-next-line guard-for-in
        if (x === '005-cards-customer-ai-kpi') {
            continue;
        }
        for (const row of Object.keys(coreData[x].data)) {
            // eslint-disable-next-line guard-for-in
            if (fieldsToMap.includes(row)) {
                for (const field of Object.keys(coreData[x].data[row])) {
                    let mapVal = coreData[x].data[row][field];
                    if (mapVal === 'ALL') {
                        continue;
                    }
                    mapVal = mapVal.split('-')[1];
                    const res = mappingData.filter((z: any) => z.id === mapVal);
                    if (res.length > 0) {
                        coreData[x].data[row][field] = res[0].displayName;
                    } else {
                        coreData[x].data[row][field] = mapVal;
                    }
                }
            }
        }
    }
    return coreData;
}

export function filterData(dataIn: any, filterColumnName: any, filterColumnValue: any, isTransformed: boolean) {

    if (isTransformed) {
        return filterTransformedData(dataIn, filterColumnName, filterColumnValue);
    }
    const dataClone = structuredClone(dataIn);
    const columnNames = Object.keys(dataClone.data);
    for (const columnName of columnNames) {
        dataClone.data[columnName] = {};
    }
    for (const rowId in dataIn.data[filterColumnName]) {
        if (dataIn.data[filterColumnName][rowId] === filterColumnValue) {
            for (const columnName of columnNames) {
                dataClone.data[columnName][rowId] = dataIn.data[columnName][rowId];
            }
        }
    }
    return dataClone;

}

export function filterTransformedData(dataIn: any, filterColumnName: any, filterColumnValue: any) {

    let dataClone = structuredClone(dataIn);
    dataClone = dataClone.filter( (x: any) => x[filterColumnName] === filterColumnValue);
    if ( dataClone.length > 0 && dataClone[0].rowId) {
        // eslint-disable-next-line guard-for-in
        for (const ww of dataClone) {
            ww.id = ww.rowId;
        }
    }
    return dataClone;

}

export function addReferenceData(data: any, referenceData: any) {
    const ret = {} as any;
    for (const pageInNav of referenceData) {
        try {
            const link =pageInNav.name.replace('pages/','').replace('.json','');
            let new_val =  JSON.parse(pageInNav.content).title ?? link;
            new_val = new_val === "" ? link : new_val;
            ret[link] = new_val;
        } catch {
            continue;
        }
    }
    const columnNamesToTransform = ['activePage', 'Page'];
    const dataClone = structuredClone(data);
    // eslint-disable-next-line guard-for-in
    for (const dataset in dataClone) {
        for (const dataColumn of Object.keys(dataClone[dataset].data)) {
            if (columnNamesToTransform.includes(dataColumn) ) {
                for (const dataRow of Object.keys(dataClone[dataset].data[dataColumn]) as any) {
                    const rowVal = (dataClone[dataset].data[dataColumn][(dataRow as any)] as string );
                    if ( Object.keys(ret).includes(rowVal)) {
                        dataClone[dataset].data[dataColumn][(dataRow as string)] = ret[rowVal];
                    }
                }
            }
        }
    }
    return dataClone;

}

export function getUniqueProfileAttributes(data: any): string[] {
    if (!data["005-summary-ai-app-preferences"]) {
        return [];
    }
    return [...new Set(Object.values(data["005-summary-ai-app-preferences"].data.profile_attr_type))] as string[];

}

export function transformProfileData(data: any): any {
    if (!data["005-summary-ai-app-preferences"]) {
        return [];
    }
    const newData = JSON.parse(JSON.stringify(data["005-summary-ai-app-preferences"]));
    newData.data.type = newData.data.profile_attr_type;
    newData.data.y_measure = newData.data.profile_attr_value ;
    newData.data.y_metric = newData.data.users;
    delete newData.data.profile_attr_type;
    delete newData.data.profile_attr_value;
    delete newData.data.users;
    data["005-summary-ai-app-preferences-chart"] = newData;
    return data;

}
