import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { useState } from "react";

import { DataGrid,
    GridRowsProp,
    GridColDef,
    GridToolbar,
    GridRowParams,
    GridRenderCellParams
} from "@mui/x-data-grid";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { Box, Typography } from "@mui/material";

import { transform_grid_data, filterData } from "./AnalyticsHelpers";
import { AnalyticsDataGridModal } from "./AnalyticsDataGridModal";

interface Props {
    data: any | undefined;
    config: any;
    title?: string;
    modalData?: any;
    modalDataSharedColumn?: string;
    transformed?: boolean | false;
    modalConfig?: any;
}

export const AnalyticsConfig_clickdetail = {
    title: "App Content Click Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "Clicks", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            activePage: {
                label: "Page"
            }
        },
        {
            aiEventValue: {
                label: "Content"
            }
        },
        {
            Clicks: {
                label: "Clicks"
            }
        },
        {
            AppUsers: {
                label: "Users"
            }
        }
    ], columns: [
        { field: "activePage", headerName: "Page", flex: 1 },
        { field: "aiEventValue", headerName: "Content", flex: 3, minWidth: 350 },
        { field: "Clicks", headerName: "Clicks", flex: 1 },
        { field: "AppUsers", headerName: "Users", flex: 1 },
    ]
};

export const AnalyticsConfig_searchdetail = {
    title: "App Search Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "FirstSeen", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            FirstSeen: {
                label: "Time"
            }
        },
        {
            activePage: {
                label: "Active Page"
            }
        },
        {
            Search: {
                label: "Search Query"
            }
        }],
    columns: [
        { field: "ViewSession", headerName: "Session", flex: 0.2,renderCell: () => {
            return (
                <>
                    <IconButton
                        color="primary"
                        aria-label="view session"
                        size="small"
                        className="bb-m0 bb-p0"
                    >
                        <VisibilityIcon />
                    </IconButton>
                </>
            );
        } },
        { field: "FirstSeen", headerName: "Time", flex: 0.5 },
        { field: "activePage", headerName: "Active Page", flex: 0.75 },
        { field: "Search", headerName: "Search Query", flex: 1 },
    ]
};

export const AnalyticsConfig_feedbackdetail = {
    title: "App Feedback Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "FirstSeen", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            FirstSeen: {
                label: "Time"
            }
        },
        {
            activePage: {
                label: "Active Page"
            }
        },
        {
            Feedback: {
                label: "User Feedback"
            }
        }],
    columns: [
        { field: "ViewSession", headerName: "Session", flex: 0.2,renderCell: () => {
            return (
                <>
                    <IconButton
                        color="primary"
                        aria-label="view session"
                        size="small"
                        className="bb-m0 bb-p0"
                    >
                        <VisibilityIcon />
                    </IconButton>
                </>
            );
        } },
        { field: "FirstSeen", headerName: "Time", flex: 0.5 },
        { field: "activePage", headerName: "Active Page", flex: 0.75 },
        { field: "Feedback", headerName: "User Feedback", flex: 1 }
    ]
};

export const AnalyticsConfig_preferencedetail = {
    title: "Onboarding Preferences",
    initialState: {
        sorting: {
            sortModel: [{ field: "profile_attr_type", sort: "asc" }, { field: "users", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            profile_attr_type: {
                label: "Attribute"
            }
        },
        {
            profile_attr_value: {
                label: "Value"
            }
        },
        {
            users: {
                label: "Users"
            }
        }],
    columns: [
        { field: "profile_attr_type", headerName: "Attribute", flex: 1, minWidth: 50 },
        { field: "profile_attr_value", headerName: "Value", flex: 1, minWidth: 50 },
        { field: "users", headerName: "Users", flex: 1, minWidth: 30 }
    ]
};

export const AnalyticsConfig_liveagentsessiondetails = {
    title: "Conversation Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "TimeGeneratedDt", sort: "asc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            TimeGeneratedDt: {
                label: "Time"
            }
        },
        {
            UserMessage: {
                label: "User Message"
            }
        },
        {
            AgentMessage: {
                label: "Agent Message"
            }
        }],
    columns: [
        { field: "TimeGeneratedDt", headerName: "Time", flex: 3, minWidth: 150 },
        { field: "UserMessage", headerName: "User Message", flex: 5, minWidth: 350 },
        { field: "AgentMessage", headerName: "Agent Message", flex: 5, minWidth: 350 }
    ]
};

export const AnalyticsConfig_liveagentsessions = {
    title: "Conversation Summary",
    initialState: {
        sorting: {
            sortModel: [{ field: "StartTimeDt", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            StartTimeDt: {
                label: "Conversation Time"
            }
        },
        {
            agentId: {
                label: "Live Agent"
            }
        },
        {
            ai_duration_minutes: {
                label: "Minutes"
            }
        },
        {
            agentMessages: {
                label: "Agent Messages"
            }
        },
        {
            userMessages: {
                label: "User Messages"
            }
        }],
    columns: [
        { field: "ViewSession", headerName: "View", flex: 0.2,renderCell: () => {
            return (
                <>
                    <IconButton
                        color="primary"
                        aria-label="view session"
                        size="small"
                        className="bb-m0 bb-p0"
                    >
                        <VisibilityIcon />
                    </IconButton>
                </>
            );
        } },
        { field: "StartTimeDt", headerName: "Conversation Time", flex: 1, minWidth: 50 },
        { field: "agentId", headerName: "Live Agent", flex: 1, minWidth: 50 },
        { field: "ai_duration_minutes", headerName: "Minutes", flex: 1, minWidth: 30 },
        { field: "agentMessages", headerName: "Agent Messages", flex: 1, minWidth: 50 },
        { field: "userMessages", headerName: "User Messages", flex: 1, minWidth: 50 }
    ]
};

export const AnalyticsConfig_liveagentsummary = {
    title: "Conversation Summary",
    initialState: {
        sorting: {
            sortModel: [{ field: "agentId", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            agentId: {
                label: "Live Agent"
            }
        },
        {
            conversations: {
                label: "Conversations"
            }
        },
        {
            conversationDuration: {
                label: "Average Minutes"
            }
        },
        {
            conversationMsg: {
                label: "Average Messages"
            }
        }],
    columns: [
        { field: "agentId", headerName: "Live Agent", flex: 1, minWidth: 50 },
        { field: "conversations", headerName: "Conversations", flex: 1, minWidth: 30 },
        { field: "conversationDuration", headerName: "Average Conversation Minutes", flex: 1, minWidth: 50 },
        { field: "conversationMsg", headerName: "Average Conversation Messages", flex: 1, minWidth: 50 }
    ]
};

const pcValueGetter = function(value: any) {
    if (!value) {
        return value;
    }
    return value * 100;
};
const pcValueFormatter = function(value?: number) {
    if (value === null) {
        return '';
    }
    return `${(value ?? 0.0).toLocaleString()} %`;
};

export const AnalyticsConfig_kpidetail = {
    title: "KPI By Month",
    initialState: {
        sorting: {
            sortModel: [{ field: "customerBot", sort: "asc" }
            ],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        { customerBot: { label: "App" } },
        { kpi_period: { label: "KPI Month" } },
        { AppUsers_Expected: { label: "Users (Predicted)" } },
        { AppUsers: { label: "Users", } },
        { PWAUsers_pc: { label: "PWA Users" } },
        { contentViewUser_pc: { label: "Viewing Content" } },
        { feedback_pc: { label: "Viewing Content" } },
        { notificationsUser_pc: { label: "Allowing Notifications" } },
        { preferenceAddedUser_pc: { label: "Updating Preferences" } },
        { savedItemUser_pc: { label: "Saving Content" } },
        { searchUser_pc: { label: "Searching" } },
        { sharedItemUser_pc: { label: "Sharing Content" } }

    ],
    columns: [
        { field: "customerBot", headerName: "App", flex: 1, minWidth: 50 },
        { field: "kpi_period", headerName: "KPI Month", flex: 1, minWidth: 50 },
        { field: "AppUsers_Expected", headerName: "Users (Predicted)", flex: 1, minWidth: 30  },
        { field: "AppUsers", headerName: "Users", flex: 1, minWidth: 30 },
        { field: "PWAUsers_pc", headerName: "PWA Users", flex: 1, minWidth: 30, valueGetter: pcValueGetter, valueFormatter: pcValueFormatter },
        {
            field: "contentViewUser_pc", headerName: "Viewing Content", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
        {
            field: "feedback_pc", headerName: "Giving Feedback", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
        {
            field: "notificationsUser_pc", headerName: "Allowing Notifications", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
        {
            field: "preferenceAddedUser_pc", headerName: "Updating Preferences", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
        {
            field: "savedItemUser_pc", headerName: "Saving Content", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
        {
            field: "searchUser_pc", headerName: "Searching", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
        {
            field: "sharedItemUser_pc", headerName: "Sharing Content", flex: 1, minWidth: 30,
            valueGetter: pcValueGetter, valueFormatter: pcValueFormatter
        },
    ]
};

export const AnalyticsConfig_pagedetail = {
    title: "App Page Visit Detail",
    initialState: {
        sorting: {
            sortModel: [{ field: "AppSessions", sort: "desc" }],
        },
        pagination: { paginationModel: { pageSize: 25 } }
    },
    columnDef: [
        {
            Page: {
                label: "Page"
            }
        },
        {
            duration: {
                label: "Duration (secs)"
            }
        },
        {
            AppUsers: {
                label: "Users"
            }
        },
        {
            AppSessions: {
                label: "Sessions"
            }
        }],
    columns: [
        { field: "Page", headerName: "Page", flex: 1, minWidth: 150,
            renderCell: (params: GridRenderCellParams<any, any>) => (
                <a href={ ('https://' + params.row.domain ?? '' ) + '?page=' + params.value} target="_blank" rel="noreferrer noopener">
                    {params.value}
                </a>
            ),
        },
        { field: "AppUsers", headerName: "Users", flex: 1 },
        { field: "AppSessions", headerName: "Sessions", flex: 1 },
        { field: "PageViews", headerName: "Page Views", flex: 1 },
        { field: "duration", headerName: "Duration (secs)", flex: 1 }
    ]
};

export const AnalyticsDataGrid = ({ data, config, title, modalData, modalDataSharedColumn, transformed, modalConfig }: Props) => {

    const [open, setOpen] = useState(false);
    const [selectedSessionData, setSelectedSessionData] = useState({});
    const [selectedSessionReferenceData, setSelectedSessionReferenceData] = useState({});
    const handleClose = () => {
        setOpen(false);
    };

    if (!data) {
        return null;
    }

    // no need to use existing header def config!!!!!!!!!!!!!!!!!!!!!!!!!!
    let transform_data;
    if (transformed) {
        transform_data = (data  as GridRowsProp);
    } else {
        transform_data = (transform_grid_data(data.data) as GridRowsProp);
    }
    const cols = (config.columns as GridColDef[]);

    return (
        <Box className="bb-analytics-tables" sx={{ width: "100%" }}>
            {title && (
                <Box sx={{ display: "flex", justifyContent: "center", background: "#fff", alignItems: "center", marginTop: "1rem" }}>
                    <Typography variant="h3" className="bb-mb-1 bb-mt-1">{title}</Typography>
                </Box>
            )}
            <Box className="bb-width-100 bb-flex">
                <Dialog open={open} maxWidth={'xl'}>
                    <DialogTitle id="analytics-sessiondetail-title" className="bb-flex bb-align-items-center">
                    </DialogTitle>
                    <DialogContent>
                        <AnalyticsDataGridModal
                            data={selectedSessionData}
                            config={modalConfig}
                            title={modalConfig?.title ?? ""}
                            transformed={transformed ?? false}
                            referenceData={selectedSessionReferenceData}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal"
                    },
                    "& .MuiDataGrid-columnHeader": {
                    // Forced to use important since overriding inline styles
                        height: "unset !important"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                    // Forced to use important since overriding inline styles
                        maxHeight: "168px !important"
                    }
                }}
                pageSizeOptions={[25]}
                rows={transform_data}
                columns={cols}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        printOptions: { disableToolbarButton: true }
                    },
                }}
                initialState={config.initialState}
                density="compact"
                disableColumnFilter={false}
                disableDensitySelector={true}
                disableColumnSelector={true}
                onRowClick={(params: GridRowParams) => {
                    if (modalDataSharedColumn && params.row[modalDataSharedColumn] && modalData) {
                        const filteredSessionData = filterData(modalData, modalDataSharedColumn, params.row[modalDataSharedColumn],  transformed ?? false);
                        setSelectedSessionData(filteredSessionData);
                        if (modalConfig.title === "Notification Detail") {
                            setSelectedSessionReferenceData(params.row);
                        }
                        setOpen(true);
                    }
                }}
            />
        </Box>
    );
};
